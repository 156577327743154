import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BankDetails } from 'src/app/classes/bank-details';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  @Input() candidateID!: number ;
  @Input() isPortalAdmin!:boolean;
  bankDetail = new BankDetails();
  messageforUser: string = '';
  loadingBankFiles: boolean = true;
  newBankDataForm: boolean = false;
  viewBankDataForm: boolean = false;
  disablefield: boolean = false;
  modalbtn: boolean = false;
  userQueryData: any = {};
  bankLength?: number;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    if(this.isPortalAdmin){
      this.getUserQuery();
    } 
  }

  getUserQuery() {
    this.loadingBankFiles = true;
    var url = `${environment.apiConfig.uri}/admin/Candidates/GetUserQuery/${this.candidateID}`;
    this.http.get(url).subscribe(async (response: any) => {
      this.userQueryData = response.data;
      this.bankLength = response.data.length;
      this.loadingBankFiles = false;
    });

  }

  newBankAccount(data: any) {
    this.modalbtn = false;
    this.disablefield = false; 
    this.newBankDataForm = true;
    this.bankDetail.account_name = data.account_name;
    this.bankDetail.account_bsb = data.account_bsb;
    this.bankDetail.account_number = data.account_number;
    this.bankDetail.secondary_account_name = data.secondary_account_name;
    this.bankDetail.secondary_account_bsb = data.secondary_account_bsb;
    this.bankDetail.secondary_account_number = data.secondary_account_number;
    this.bankDetail.deposit_amount = data.deposit_amount;
    this.bankDetail.deposit_type = data.deposit_type;
    this.bankDetail.uid = data.mid.toString();
    this.bankDetail.remoteid = data.remoteid;
  }

  viewBankAccount(data: any) {
    this.disablefield = true;
    this.newBankDataForm = false;
    this.viewBankDataForm = true;
    this.bankDetail.account_name = data.account_name;
    this.bankDetail.account_bsb = data.account_bsb;
    this.bankDetail.account_number = data.account_number;
    this.bankDetail.secondary_account_name = data.secondary_account_name;
    this.bankDetail.secondary_account_bsb = data.secondary_account_bsb;
    this.bankDetail.secondary_account_number = data.secondary_account_number;
    this.bankDetail.deposit_amount = data.deposit_amount;
    this.bankDetail.deposit_type = data.deposit_type;
  }

  bankAccountSubmit(data: any) {
    this.messageforUser = '';
    if (
      !data.account_name ||
      !/^[\w\s\d]+$/.test(data.account_name) ||
      !data.account_bsb ||
      !/^(?!0+$)[0-9]{4,}$/.test(data.account_bsb)||
      !data.account_number ||
      !/^[0-9]+$/.test(data.account_number)
    ) {
      return (this.messageforUser = 'Please complete Primary account details.');
    } else if (
      (data.secondary_account_name &&
        !/^[\w\s\d]+$/.test(data.secondary_account_name)) ||
      (data.secondary_account_bsb &&
        !/^(?!0+$)[0-9]{4,}$/.test(data.secondary_account_bsb)) ||
      (data.secondary_account_number &&
        !/^[0-9]+$/.test(data.secondary_account_number))
    ) {
      return (this.messageforUser =
        'Please complete Secondary account details.');
    }

    else {
      this.bankDetail.account_name = data.account_name ? data.account_name : '';
      this.bankDetail.account_bsb = data.account_bsb
        ? data.account_bsb.toString()
        : '';
      this.bankDetail.account_number = data.account_number
        ? data.account_number.toString()
        : '';
      this.bankDetail.secondary_account_name = data.secondary_account_name
        ? data.secondary_account_name
        : '';
      this.bankDetail.secondary_account_bsb = data.secondary_account_bsb
        ? data.secondary_account_bsb.toString()
        : '';
      this.bankDetail.secondary_account_number = data.secondary_account_number
        ? data.secondary_account_number.toString()
        : '';
      this.bankDetail.deposit_type = data.deposit_type;
      this.bankDetail.deposit_amount = data.deposit_amount
        ? data.deposit_amount.toString()
        : '';

      var url = `${environment.apiConfig.uri}/admin/Candidates/AddBankDetails/${this.candidateID}`;
      this.modalbtn = false;
      return this.http
        .post(url, this.bankDetail)
        .subscribe(async (response: any) => {
          if (response.isSuccess) {
            this.modalbtn = true;
            this.messageforUser = 'Bank Details added successfully';
            this.getUserQuery();
          } else {
            this.messageforUser = 'Error! Please try again with correct details.';
          }
        });
    }

  }

}
