import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-candidate-modal',
  templateUrl: './candidate-modal.component.html',
  styleUrls: ['./candidate-modal.component.css'],
})
export class CandidateModalComponent implements OnInit {
  @Input() candidateID: any;
  @Input() isPortalAdmin!:boolean
  active = 1;
  showNewTaxFile:boolean=false;
  candidateName:string='';
  candidateEmail:string='';

  constructor(public activeModal: NgbActiveModal, private http: HttpClient) {}

  ngOnInit(): void {
    var payAdviceUrl = environment.apiConfig.uri + `/admin/Candidates/GetPayAdvices/${this.candidateID}`;
    this.http.get(payAdviceUrl).subscribe(async (res: any) => {
      this.candidateName=res.data.name;
      this.candidateEmail=res.data.email;      
    });
  }

  receiveParentValue(value: boolean) {
    this.showNewTaxFile=value;
  }
}
