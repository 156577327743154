<div class="blur-content">
  <div [ngClass]="{ 'page-container': true, sbar_collapsed: mobileView }" style="background-color: #f2f8fa !important">
    <app-sidebar-menu [selected]="selected" [isAdmin]="true"></app-sidebar-menu>
    <!-- main content area start -->
    <div class="main-content">
      <app-header></app-header>
      <div class="main-content-inner">
        <!-- market value area start -->
        <div class="row mt-5">
          <div class="col-12" style="padding-right: 0 !important; margin-right: 0 !important">
            <div class="card">
              <div class="card-body">
                <div *ngIf="loadingWorkflow===false">
                  <div class="d-flex">
                    <h4>Workflow Actions</h4>
                  </div>
                  <hr>
                  <div class="table-responsive">
                    <table class="table table-sm text-sm table-bordered">
                      <thead>
                        <tr class="text-center">
                          <!-- Always visible headings -->
                          <th [attr.colspan]="!areAdditionalHeadingsVisible ? 4 : 1">
                            <div class="flex-container" (click)="sort('workflowTitle')">
                              Workflow Title <span><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th [attr.colspan]="!areAdditionalHeadingsVisible ? 2 : 1">
                            <div class="flex-container" (click)="sort('entityName')">
                              Entity Name <span><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <!-- Conditionally visible headings -->
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Entity Id
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Step
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Action
                          </th>
                          <th [attr.colspan]="!areAdditionalHeadingsVisible ? 2 : 1">
                            <div class="flex-container" (click)="sort('departmentName')">
                              Department<span><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Assign To
                          </th>
                          <th [attr.colspan]="!areAdditionalHeadingsVisible ? 3 : 1">
                            <div class="flex-container" (click)="sort('createdAT')">
                              Date Created<span><i class="fa fa-sort"></i></span>
                            </div>
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Date Due
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible">
                              Status
                          </th>
                          <th *ngIf="areAdditionalHeadingsVisible" class="text-center">File</th>
                          <th *ngIf="areAdditionalHeadingsVisible"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="groupedWorkflows && getGroupKeys().length > 0">
                          <ng-container *ngFor="let groupKey of getGroupKeys()">
                            <!-- Parent Row -->
                            <tr class="parent-row" (click)="toggleGroup(groupKey)">
                              <td colspan="4">{{ groupedWorkflows[groupKey].data[0]?.workflowTitle }}</td>
                              <td colspan="2">{{ groupedWorkflows[groupKey].entityName }}</td>
                              <td colspan="2">{{ groupedWorkflows[groupKey].departmentName}}</td>
                              <td colspan="2">{{ groupedWorkflows[groupKey].createdAT | date: 'dd-MM-yyyy'  }}</td>
                              <td colspan="2" class="text-center">
                                <button class="btn btn-sm btn-info">
                                  <svg *ngIf="groupedWorkflows[groupKey].isOpen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708z"/>
                                    <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                                  </svg>
                                  <svg *ngIf="!groupedWorkflows[groupKey].isOpen" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                    <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            <!-- Child Rows -->
                            <ng-container *ngIf="groupedWorkflows[groupKey].isOpen">
                              <tr *ngFor="let workflow of groupedWorkflows[groupKey].data" class="text-center">
                                <td>{{ workflow.workflowTitle }}</td>
                                <td>{{ workflow.entityName }}</td>
                                <td>{{ workflow.triggerEntityId }}</td>
                                <td>{{ workflow.stepNumber }}</td>
                                <td>{{ workflow.title }}</td>
                                <td>{{ workflow.departmentName }}</td>
                                <td><select id="assignTo" name="assignTo" class="custom-select custom-select-sm" [(ngModel)]="workflow.assignTo"
                                    (change)="onAssignTo(workflow)">
                                    <option value="" disabled selected> Select</option>
                                    <option *ngFor="let value of admins" [value]='value'>{{ value }}</option>
                                  </select></td>
                                <td>{{ workflow.createdAT | date: 'dd-MM-yyyy' }}</td>
                                <td><input type="date" (keydown)="onKeyDown($event)" pattern="'dd-MM-yyyy'" title="Select Date From Calendar" class="form-control"
                                    [(ngModel)]="workflow.dueDate" (ngModelChange)="onDueDateChange(workflow)"></td>
                                <td> <select id="status" name="status" class="custom-select custom-select-sm" [(ngModel)]="workflow.status"
                                    (change)="onStatusChange(workflow)">
                                    <option value="" disabled selected> Status</option>
                                    <option *ngFor="let value of status" [value]='value'>{{ value }}</option>
                                  </select></td>
                                <td><button class="btn btn-sm btn-info" [title]="workflow.fileName && workflow.fileId ? 'View File' : 'No File'"
                                    (click)="onClickView(workflow)" [disabled]="!workflow.fileName || !workflow.fileId">
                                    <i class="fa fa-file-text-o" style="font-size: 18px"></i>
                                  </button></td>
                                <td class="text-center">
                                  <button title="Delete" class="btn btn-sm btn-info ml-1" (click)="deleteworkflowAction(workflow)">
                                    <i class="fa fa-trash-o" style="font-size: 18px"></i>
                                  </button>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                    
                  </div>
                </div>
                <div *ngIf="loadingWorkflow===true">
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load" id="long"></div>
                  </li>
                  <li class="t_loader">
                    <div class="card__desc load"></div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- market value area end -->
      </div>
      <!-- main content area end -->
    </div>
  </div>
</div>

<app-confirmation-modal 
  [title]="'Delete Workflow Action'"
  [message]="'Are you sure you want to delete this Workflow Action?'"
  [note]="'(Note: This action cannot be undone)'"
  [confirmButtonText]="'Delete'"
  [cancelButtonText]="'Cancel'"
  [successMessage]="successMessage"
  [loading]="loading"
  [(isVisible)]="isModalVisible"
  (confirm)="confirmDelete()"
  (cancel)="onCancel()"
  (close)="onClose()"
></app-confirmation-modal>