import { Component } from '@angular/core';
import { CandidateService } from '../../services/candidate.service';
import { Candidate } from '../../models/Candidate';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CandidateModalComponent } from '../candidate/candidate-modal/candidate-modal.component';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { PortalAdminService } from 'src/app/services/portal-admin.service';
import { retry, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas'];
@Component({
  selector: 'app-candidate-search',
  templateUrl: './candidate-search.component.html',
  styleUrls: ['./candidate-search.component.css'],
})
export class CandidateSearchComponent {
  public model: any;
  candidates: Candidate[] = [];
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  typeAhead: string[] = states;
  loading: boolean = true;
  selected: string = 'candidate-search';
  candidateId!: string;
  errorMessage : string = '';
  isPortalAdmin!:boolean;

  constructor(
    private candidateService: CandidateService,private http:HttpClient,private authService: MsalService,
    private modalService: NgbModal, private portalAdminService:PortalAdminService
  ) {}

  ngOnInit(): void {    
  }

  handlePortalAdminStatus(isAdmin: boolean) {
    this.isPortalAdmin = isAdmin;
  }

  public openViewModel = (id: any) => {
    this.candidateId = id;
    const modalRef = this.modalService.open(CandidateModalComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.result
      .then((result) => {
        this.loading = true;
        this.candidateService
        .getCandidates(this.page.toString(), "", this.pageSize)
        .subscribe((response: any) => {
          this.candidates = response.data;
          this.candidates.forEach((obj) => {
            if (obj.status != 'Completed') {
              return (obj.status = 'Incomplete');
            } else {
              return (obj.status = 'Complete');
            }
          });
          this.collectionSize = response.extraParam.totalCount;
          this.errorMessage='';
          this.loading = false;
        });
      })
      .catch((error) => {
        console.error(error);
      });
    modalRef.componentInstance.candidateID = id;
    modalRef.componentInstance.isPortalAdmin = this.isPortalAdmin;
  };
  public refreshCandidates = (
    page: string = '1',
    query: string = '',
    pageSize: string = '10'
  ) => {
    this.errorMessage='';
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.candidates = [];
    this.candidateService
      .getCandidates(this.page.toString(), query, this.pageSize)
      .pipe(
        retry(1), // Will retry failed request once before failing
        catchError(error => {
          this.loading = false;
          this.errorMessage = 'Oops, something went wrong while fetching candidates. Please try again later.';
          return of(null); // Return an empty observable to prevent the error from propagating
        })
      ).subscribe((response: any) => {
        this.candidates = response.data;
        this.candidates.forEach((obj) => {
          if (obj.status != 'Completed') {
            return (obj.status = 'Incomplete');
          } else {
            return (obj.status = 'Complete');
          }
        });
        this.collectionSize = response.extraParam.totalCount;
        this.errorMessage='';
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorMessage = 'Oops, something went wrong while fetching candidates. Please try again later.';
      });
  };
}
