import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Deduction } from 'src/app/models/Deduction';
import * as html2pdf from 'html2pdf.js';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-pending-deductions',
  templateUrl: './pending-deductions.component.html',
  styleUrls: ['./pending-deductions.component.css']
})

export class PendingDeductionsComponent implements OnInit {
  loadingDeductions:boolean=true;
  selected:string='pending-deductions'
  mobileView: boolean = false;
  pendingDeduction:Deduction[]=[];
  message:string='';
  removeDeductionFlag:boolean=false;
  editDeductionFlag:boolean=false;
  deductionToMoved:any;
  filter: { id: number | null; candidateName: string; name: string; note: string } = {
    id: null,
    candidateName: '',
    name: '',
    note: ''
  };
  filteredDeductions:any;
  editData!: Deduction;
  isSubmitting = false;
  submissionMessage: string | null = null;
  isSuccess = false;
  isFormSubmitted = false; // Flag to check if form is submitted
  isLoading = false; // Flag to check if loading is in 
  showValidationError = false;
  submitFailed = false;
  selectedRows: any[] = []; // Store selected rows
  brandPhone:string='1300 247 823';
  brandEmail:string='info@agrilabour.com.au';
  brandName:string='Agri Labour Australia';
  brandUrl:string='www.agrilabour.com.au';
  brandLogo:string = 'assets/img/agrilabour-logo-01.png';
  groupedDeductions: { [key: string]: Deduction[] } = {};
       
    constructor(private http:HttpClient,private route: ActivatedRoute,@Inject(DOCUMENT) private document: Document) {
    this.route.params.subscribe((params) => {
      if (window.screen.width <= 768) {
        this.toggleSidebar();
      }
    });
   }

  ngOnInit(): void {
    this.getPendingDeductions();
    const brandHostname = this.document.location.hostname;
    if (brandHostname.includes('candidates.healthpluspeople.com.au')){
      this.brandLogo = 'assets/img/health_People.png';
      this.brandEmail='info@healthpluspeople.com.au';
      this.brandPhone='1300 367 719';
      this.brandName='Health Plus People';
      this.brandUrl='healthpluspeople.com.au';
    }
  }

  applyFilter() {
    this.filteredDeductions = this.pendingDeduction.filter((item: { uid: { toString: () => string | any[]; }; candidateName: string; name: string; note: string; }) => {
      const filterIdMatch = this.filter.id === null || item.uid.toString().includes(this.filter.id.toString());
      const filterCandidateNameMatch = !this.filter.candidateName || item.candidateName.toLowerCase().includes(this.filter.candidateName.toLowerCase());
      const filterNameMatch = !this.filter.name || item.name.toLowerCase().includes(this.filter.name.toLowerCase());
      const filterNoteMatch = !this.filter.note || item.note.toLowerCase().includes(this.filter.note.toLowerCase());      
      return filterIdMatch && filterCandidateNameMatch && filterNameMatch && filterNoteMatch;
    });
  }

  editDeduction(data: Deduction) {
  this.clearErrors();
  this.isFormSubmitted=false;
  this.removeDeductionFlag=false;
  data.date_from = data.date_from ? data.date_from.split('T')[0] : '';
  data.date_to = data.date_to ? data.date_to.split('T')[0] : '';
  this.editData = data;
  this.editDeductionFlag = true;
  } 
  
  toggleSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.toggle('sbar_collapsed');
  }
  closeSidebar() {
    var menu = document.querySelector('.page-container');
    menu?.classList.add('sbar_collapsed');
  }

  selectDeduction(item: any) {
    if (item.selected) {
      this.selectedRows.push(item);
    } else {
      this.selectedRows = this.selectedRows.filter(row => row !== item);
    }
  }

    async generatePDF() {
      // Group selected rows by candidate
      const groupedData = this.groupBy(this.selectedRows, 'candidateName');
    
      // Create a temporary container to hold data for the PDF
      const pdfContainer = document.createElement('div');
      pdfContainer.id = 'pdf-container';
    
      // Get all candidate names
      const candidateNames = Object.keys(groupedData);
    
      // Iterate through each candidate and create a page for their deductions
      candidateNames.forEach((candidateName, index) => {
        const candidateData = groupedData[candidateName];
    
        // Create a container for the candidate's data
        const candidateContainer = document.createElement('div');
        candidateContainer.style.cssText = `
          position: relative;
          min-height: 100vh;
          padding: 20px;
          display: flex;
          flex-direction: column;
        `;
    
        // Apply page break style only if it's not the last candidate
        if (index !== candidateNames.length - 1) {
          candidateContainer.style.pageBreakAfter = 'always';
        }
    
        // Add candidate header
        const candidateHeader = document.createElement('h4');
        candidateHeader.style.textAlign = 'center';
    
        // Create table for the candidate's deductions
        const table = document.createElement('table');
        table.style.width = '100%';
        table.style.borderCollapse = 'collapse';
        table.style.marginBottom = '20px';
    
        // Add table headers (adjust as per your table's columns)
        const headers = `
        <div [style]="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
          <div class="mr-3">
            <table style="width: 100%; border-collapse: collapse; border: none !important;">
                <tr style="font-size: 10px; color: #818285;" style="border: none !important;">
                    <td colspan='2' style="border: none !important;"> 
                      <div class="px-2 py-2">
                          <img src=${this.brandLogo} alt="logo" style='width=30%' />
                      </div>
                    </td>
                    <td colspan='2' style="border: none !important;"></td>
                    <td colspan='6' style="border: none !important;">
                        ${this.brandPhone} <br>
                        G01N 527 Gregory Terrace, <br>
                        Fortitude Valley QLD 4006 <br>
                        ABN 23 142 526 216 <br>
                        ACN 142 526 216 <br>
                        ${this.brandUrl} <br>
                        <hr style="background-color: #0C578E;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 448 512" class="mr-2"
                            style="color: #0C578E;">
                            <path
                                d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                                fill="#0C578E" />
                        </svg>${this.brandName}<br>
                        <span *ngIf="brandName !== 'Health Plus People'">
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="10" viewBox="0 0 320 512"
                                class="mr-2" style="color: #0C578E;">
                                <path
                                    d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                                    fill="#0C578E" />
                            </svg>&#64;agrilabouraus<br>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 448 512"
                                class="mr-2" style="color: #0C578E;">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                    fill="#0C578E" />
                            </svg>&#64;agrilabourau<br>
                        </span>
                    </td>
                </tr>
                 <tr>
                  <td colspan="10" style="height: 80px; border:none !important;"></td>
                 </tr>
                <tr style="border: none !important;">
                  <td colspan='10' style="border: none !important;"> <h3><strong>AUTHORITY FOR PAYROLL DEDUCTIONS</strong></h3></td>
                </tr>
                <tr>
                <td colspan='10' style="border: none !important;"> 
                    <h6>I <strong> ${candidateName || ''} </strong>authorize ${this.brandName} Pty Ltd to make
                    deductions to my pay, as specified in the table below.</h6>
                  </td>
                </tr>
                <tr style="border: 1px solid #000; margin-top: 10px; background-color: #f8f9fa;">
                    <th style="border: 1px solid #000; padding: 2px;">Description</th>
                    <th style="border: 1px solid #000; padding: 2px;">Amount Each Pay</th>
                    <th style="border: 1px solid #000; padding: 2px">Total Amount</th>
                    <th style="border: 1px solid #000; padding: 2px">Commencing</th>
                    <th style="border: 1px solid #000; padding: 2px">Note</th>
                </tr>
            </table>
        </div>
      </div>`;
        table.innerHTML += headers;
        // Add deductions for the candidate
        candidateData.forEach((item: {
          limit_fixed: any; name: any;  limit_type: string; date_from: any;  amount: number;  note: any;}) => {
          const row = `
            <tr> 
              <td style="border: 1px solid #000; padding: 2px">${item.name || ''}</td>
              <td style="border: 1px solid #000; padding: 2px">${item.amount ? `$`+item.amount : ''}</td>
              <td style="border: 1px solid #000; padding: 2px">${(item.limit_fixed && item.limit_fixed != 0) ? `$`+item.limit_fixed : ''}</td>
              <td style="border: 1px solid #000; padding: 2px">${this.formatDate(item.date_from)}</td>
              <td style="border: 1px solid #000; padding: 2px">${item.note || ''}</td>
            </tr>
          `;
          table.innerHTML += row;
        });

        const footer = `
            <tr style="border: none !important; position: absolute; bottom: 0px; left: 0; right: 0; width: 100%; padding: 20px;">
              <td colspan="10" style="width: 100%; padding: 10px; margin-top:80px; border: none !important;">
                <div class="bottom" style="margin-top: 50px;">
                  <div>
                    <p style="margin-bottom: 2px; "><b>Signature:</b></p>
                    <p style="margin-bottom: 2px;"><b>Date:</b></p>
                  </div>
                   <div style="margin-top: 8px;">
                      <h2 style="color: #ECB41F; margin-bottom: 2px;">LABOUR HIRE</h2>
                      <h2 style="color: #624891;">PERMANENT RECRUITMENT</h2>
                   </div>
                </div>
              </td>
            </tr>
            `;
            table.innerHTML += footer;
        // Append table to the candidate container
        candidateContainer.appendChild(table);
  
        // Append candidate container to the PDF container
        pdfContainer.appendChild(candidateContainer);
      });
    
      // Append the container to the body (for rendering purposes)
      document.body.appendChild(pdfContainer);
    
      // Define PDF options
      const pdfOptions = {
        margin: 10,
        filename: 'Pending_Deductions.pdf',
        image: { type: 'png', quality: 0.9 },
        html2canvas: { scale: 2},
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
   
    
      try {
        // Generate the PDF
        await html2pdf().from(pdfContainer).set(pdfOptions).save();
      } catch (error) {
        console.error('Error generating PDF:', error);
      } finally {
        // Remove the temporary container
        document.body.removeChild(pdfContainer);
      }
    }
    
  // Helper function to group data by a key
  groupBy(array: any[], key: string) {
    return array.reduce((result, currentValue) => {
      const groupKey = currentValue[key] || 'Unknown';
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  }
  getPendingDeductions() {
    this.loadingDeductions = true;
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetAddedDeductions`;    
    this.http.get(url).subscribe(
      (response: any) => {
        this.pendingDeduction = response.data.filter((data:Deduction)=>data.status===1) || []; 
        this.pendingDeduction.sort((a:any, b:any) => b.id - a.id);
        this.filteredDeductions = this.pendingDeduction;
        this.loadingDeductions = false;
      },
      (error) => {
        console.error("Error fetching deductions:", error);
        this.loadingDeductions = false;
      }
    );
    this.filter.candidateName='';
    this.filter.id=null;
    this.filter.name='';
    this.filter.note='';
  }

  removededuction(data: Deduction) {
    this.editDeductionFlag=false;
    this.removeDeductionFlag=true;
    this.deductionToMoved=data;
    this.message='Are you sure you want to remove this deduction? This will remove it from the list.'; 
  }

  editDataErrors = {
    startDate: '',
    amount: '',
    limit_fixed: '',
  };

  submitEditDeduction() {
    this.clearErrors();
    const errors = this.validateDeduction(this.editData);
    if (errors.length > 0) {
      this.showValidationError = true;
      this.isLoading = false;
      return;
    }
    this.showValidationError = false;
    this.isLoading = true;
    const payload: any = {
    id: this.editData.id,
    adminId: this.editData.adminId,
    candidateName: this.editData.candidateName,
    employer: this.editData.employer,
    pdid: this.editData.pdid,
    uid: this.editData.uid,
    name: this.editData.name,
    limit_type: this.editData.limit_type,
    status: this.editData.status,
    liability_account_code: this.editData.liability_account_code,
    candidateId: this.editData.candidateId,
    batchId: this.editData.batchId,
    }
    if (this.editData.date_from) payload.date_from = this.editData.date_from;
    if (this.editData.amount) payload.amount = this.editData.amount?.toString();
    if (this.editData.limit_fixed) payload.limit_fixed = this.editData.limit_fixed;
    if (this.editData.note) payload.note = this.editData.note;
    if (this.editData.amount_taken) payload.amount_taken = this.editData.amount_taken;
    if (this.editData.createdAt) payload.createdAt = this.editData.createdAt;
    if (this.editData.date_to) {payload.date_to = this.editData.date_to;}
    if (this.editData.isAstute) payload.isAstute = this.editData.isAstute;
    const url = `${environment.apiConfig.uri}/admin/Candidates/EditDeduction`;
    this.http.post(url, payload).subscribe(
      response => {
        this.isLoading = false;
        this.isFormSubmitted = true;
        this.message = 'Deduction updated successfully';
        this.getPendingDeductions();
      },
      error => {
        this.isLoading = false;
        this.submitFailed = true; 
        this.message = 'Failed to update deduction';
      }
    );
  }
  
  validateDeduction(data: Deduction): string[] {
    const errors: string[] = [];
     // Convert amount and limit_fixed to numbers for proper comparison
    const validatedAmount = parseFloat(data.amount);
    const validatedLimitFixed = parseFloat(data.limit_fixed);
    // Validation for "recurring"
    if (data.limit_type === 'unlimited') {
      if (!data.date_from) {
        this.editDataErrors.startDate = 'Please provide a Start Date.';
        errors.push('Start Date is required.');
      }
      if (!validatedAmount) {
        this.editDataErrors.amount = 'Enter a valid Amount with a maximum of 6 digits.';
        errors.push('Amount is required.');
      }
    }
  
    if (data.limit_type === 'fixed_amount' || data.limit_type === 'fixed_pay_period') {
      if (!data.date_from) {
        this.editDataErrors.startDate = 'Please provide a Start Date.';
        errors.push('Start Date is required.');
      }
      if (!validatedAmount) {
        this.editDataErrors.amount = 'Enter a valid Amount with a maximum of 6 digits.';
        errors.push('Amount is required.');
      }
      if (!validatedLimitFixed) {
        this.editDataErrors.limit_fixed = 'Enter a valid Limit with a maximum of 7 characters.';
        errors.push('Limit is required.');
      }
      if (validatedAmount > validatedLimitFixed) {
        this.editDataErrors.amount = 'Amount cannot be greater than the deduction limit.';
        errors.push('Amount cannot be greater than limit.');
      }
    }
    return errors;
  }
  
  clearErrors() {
    this.editDataErrors = {
      startDate: '',
      amount: '',
      limit_fixed: '',
    };
  }

  formatDate(date: any): string {
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  }

  confirmCancel(){
    const data=this.deductionToMoved;
    const PDID=data.pdid;
    const UID=data.uid;
    const id =data.id;
    const batchId = data.batchId ? `&batchId=${data.batchId}` : '';
    const url = `${environment.apiConfig.uri}/admin/Candidates/DeleteAllDeductions?id=${id}&PDID=${PDID}&UID=${UID}${batchId}`;
    
    this.http.delete(url).subscribe(() => {
      this.getPendingDeductions()
        this.message='Deduction deleted successfully.'
      },
      error => {
        console.error('Error deleting deduction:', error);
        this.message='Error deleting deduction:'
      }
    );

  }

}
