import { Component, Input, OnInit,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import html2pdf from 'html2pdf.js';
import { forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-candidate-deductions',
  templateUrl: './candidate-deductions.component.html',
  styleUrls: ['./candidate-deductions.component.css'],
})
export class CandidateDeductionsComponent implements OnInit {
  @Input() candidateID: string = '';
  deductionData: any;
  mid!: number;
  unlimitedData: any;
  fixedData: any;
  queryData: any;
  dataATD: any;
  currentDate: any;
  mobile: any;
  submissionDate: any;
  elementRef: any;
  file: any;
  successMessage: string = '';
  loading: boolean = false;
  unlimitTable: boolean = false;
  fixTable: boolean = false;
  loadingFiles: boolean = true;
  openDeduction: boolean = false;
  docuSignUrl:string='';
  @Input() name: string='';
  @Input() email: string='';
  candidateName:string=''
  employeer:string='';
  payOutId:string='';
  brandLogo:string = 'assets/img/agrilabour-logo-01.png';
  brandPhone:string='1300 247 823';
  brandEmail:string='info@agrilabour.com.au'
  brandName:string='Agri Labour Australia';
  brandUrl:string='www.agrilabour.com.au';
  redirectUrl:string='';

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.redirectUrl = window.location.href;
    const brandHostname = this.document.location.hostname;
    if (brandHostname.includes('candidates.healthpluspeople.com.au')){
      this.brandLogo = 'assets/img/health_People.png';
      this.brandEmail='info@healthpluspeople.com.au';
      this.brandPhone='1300 367 719';
      this.brandName='Health Plus People';
      this.brandUrl='healthpluspeople.com.au';
    }
      this.getQuery();
  }

  getQuery() {
    const url = `${environment.apiConfig.uri}/admin/Candidates/GetUserQuery/${this.candidateID}`;
    this.http.get(url).subscribe((res: any) => {
      this.queryData = res.data;
      this.loadingFiles = false;
    });
  }

  openDocuSign(){
    window.location.href = this.docuSignUrl;
  }


  viewDeduction(item: any): void {
    this.successMessage = '';
    this.loadingFiles = true;
    this.mid = item.mid;
    this.employeer = item.companyName;
    this.candidateName = item.name_first + " " + item.name_last;
    
    // First API call
    const apiUrl = `${environment.apiConfig.uri}/AstutePayAdvice/GetDeduction/${this.mid}`;
    const firstApiCall = this.http.get(apiUrl);
  
    // Second API call
    const secondUrl = `${environment.apiConfig.uri}/admin/Candidates/GetDeductionMetaData/${this.mid}`;
    const secondApiCall = this.http.get(secondUrl);
  
    // Using forkJoin to wait for both API calls to complete
    forkJoin([firstApiCall, secondApiCall]).subscribe((responses: any[]) => {
      const [firstApiResponse, secondApiResponse] = responses;
      
      this.unlimitedData = firstApiResponse.data.unlimited;
      this.fixedData = firstApiResponse.data.fixed_amount;
      this.unlimitTable = this.unlimitedData.length > 0;
      this.fixTable = this.fixedData.length > 0;
  
      const metaData = secondApiResponse.data.map((item: any) => {
        return {
          ...item, // Keep all existing properties
          amount: parseFloat(item.amount).toFixed(2), // format amount to 2 decimal places
          date_from: item.date_from.split('T')[0],
        };
      });
  
      // Iterate through unlimitedData and metaData to update fileId
      this.unlimitedData.forEach((unlimitedItem: any) => {
        metaData.forEach((metaItem: any) => {
          if (
            unlimitedItem.pdid == metaItem.pdid && unlimitedItem.deductionId == metaItem.deductionId &&
            parseFloat(unlimitedItem.amount).toFixed(2) ==
            parseFloat(metaItem.amount).toFixed(2) &&
            unlimitedItem.date_from == metaItem.date_from
          ) {
            unlimitedItem.isSigned = metaItem.isSigned;
            unlimitedItem.fileId = metaItem.fileId;
            unlimitedItem.status = metaItem.status;
            unlimitedItem.envelopeId = metaItem.envelopeId;
          }
        });
      });
  
      this.fixedData.forEach((fixedItem: any) => {
        metaData.forEach((metaItem: any) => {
          if (
            fixedItem.pdid == metaItem.pdid && fixedItem.deductionId == metaItem.deductionId &&
            parseFloat(fixedItem.amount).toFixed(2) ==
            parseFloat(metaItem.amount).toFixed(2) &&
            fixedItem.date_from == metaItem.date_from
          ) {
            fixedItem.isSigned = metaItem.isSigned;
            fixedItem.fileId = metaItem.fileId;
            fixedItem.status = metaItem.status;
            fixedItem.envelopeId = metaItem.envelopeId;
          }
        });
      });
  
      // Code to run after the foreach loops and API calls
      this.loadingFiles = false;
      this.openDeduction = true;
    },
    (error) => {
      console.error("Error fetching data:", error);
      this.loadingFiles = false;
    });
  }

  formatDateString(dateString:any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private formatData(dataItems: any[]): any[] {
    return dataItems.map((dataItem: any) => ({
      ...dataItem,
      amount: parseFloat(dataItem.amount).toFixed(2),
      amount_taken: parseFloat(dataItem.amount_taken).toFixed(2),
      balance:
        dataItem.balance === 'No'
          ? '-'
          : parseFloat(dataItem.balance).toFixed(2),
      date_to: this.formatDate(dataItem.date_to),
      date_from: this.formatDate(dataItem.date_from),
    }));
  }

  private formatDate(dateString: string | null): Date | string {
    if (!dateString || dateString === '-' || dateString === 'PAID') {
      return dateString === 'PAID' ? 'PAID' : '-';
    }
    return new Date(dateString.replace(/\s+/g, ' '));
  }

  createDeductionSlip(data: any) {
    if(data.batchId){
       // Filter fixedData and unlimitedData to find entries with the same batchId
    const fixedDataMatches = this.fixedData.filter((item: { batchId: any; }) => item.batchId === data.batchId);
    const unlimitedDataMatches = this.unlimitedData.filter((item: { batchId: any; }) => item.batchId === data.batchId);
    // Combine the matching entries
    this.dataATD = [...fixedDataMatches, ...unlimitedDataMatches];
    }
    else{
      this.dataATD = [data];
    }
    this.successMessage = '';
    this.currentDate = new Date();
  }

  async submitATD() {
    this.docuSignUrl='';
    this.submissionDate = new Date().toLocaleString();
    const pdfdata = document.getElementById('ATD_pdf');
    // const deductionId=this.dataATD.deductionId;
  
    if (pdfdata) {
      const pdfOptions = {
        margin: 10,
        filename: `Authority to Deduct  – ${this.dataATD[0].name?this.dataATD[0].name:"Multi"} - ${this.name}.pdf`,
        image: { type: 'png', quality: 0.9 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
  
      try {
        this.loading = true;
        // Generate PDF using html2pdf
        const pdf = await html2pdf().from(pdfdata).set(pdfOptions).outputPdf();
        const pdfBase64 = this.encodeToBase64(pdf);  
        const deductionObj = this.dataATD.map((item: {
          liability_account_code: any;
          date_to: any; limit_fixed: any;
          amount_taken: any; old_PSID: any;
          pcid: any; limit_type: any;isPayOut:any;
          amount_owed: any; balance: any;
          note: any; uid: any; pdid: any; amount: any; date_from: any;
          name: any; deductionId: any; batchId:any;isAstute:any }) => {
          return {            
            epdiid: 0,
            uid: item.uid,
            pdid: item.pdid,
            amount: item.amount,
            active: "yes",
            date_from: item.date_from,
            date_to: item.date_to,
            limit_fixed: item.limit_fixed,
            amount_taken: item.amount_taken,
            old_PSID: item.old_PSID,
            name: item.name,
            pcid: item.pcid,
            limit_type: item.limit_type,
            amount_owed: item.amount_owed,
            total_paid: item.amount_owed,
            balance: item.balance,
            liability_account_code: item.liability_account_code,           
            isAstute: item.isAstute,
            note: item.note,
            isPayOut:item.isPayOut,
            deductionId: item.deductionId,
            batchId: item.batchId,
            // isSigned: false,            
          };
        });  
        const FileObj = {
          deduction: deductionObj,
          file: pdfBase64,
          candidatename: this.candidateName,
          employer: this.employeer
        };
        const url = `${environment.apiConfig.uri}/admin/Candidates/SaveATDHistory/${this.candidateID}?fileName=${encodeURIComponent(`Authority to Deduct  – ${this.dataATD[0].name?this.dataATD[0].name:'Multi'} - ${this.name}.pdf`)}&receiverEmail=${encodeURIComponent(this.email)}&receiverName=${encodeURIComponent(this.name)}&url=${this.redirectUrl}&payOutId=${this.payOutId}`;
        const response = await this.http.post<any>(url, FileObj).toPromise();
        this.successMessage = 'Please Open below to add your signature to the document.';
        this.docuSignUrl=response.data;  
        this.loading = false;
      } catch (error) {
        this.loading = false; 
        console.error('Error generating or submitting PDF:', error);
      }
    } else {
      this.loading = false; 
      console.error('Content is empty or element not found.');
    }
  }
  
  private encodeToBase64(data: string): string {
    return btoa(data);
  }

  onClickDownload(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const link = document.createElement('a');
      link.download = `${item.name}`;
      const source = `data:application/pdf;base64,${item.fileStream}`;
      link.href = source;
      link.click();
    }
  }
  
  getFileStream(item: any, view: boolean = false) {
    var url = environment.apiConfig.uri + `/admin/File/GetFileStream/Candidate/${item.fileId}`;
    this.http.get(url).subscribe(
      (response: any) => {
        this.file = response.data || '';
        item.fileStream = this.file;
        if (view) {
          this.onClickView(item);
        } else {
          this.onClickDownload(item);
        }
      },
      (error) => {
        console.error('Error fetching file stream:', error);
      }
    );
  }
  
  onClickView(item: any) {
    if (!item.fileStream) {
      this.getFileStream(item, true);
    } else if (item.fileStream && item.fileStream.trim().length > 0) {
      const link = document.createElement('a');
      link.target = '_blank';
      const blob = this.dataURItoBlob(item);
      const file = new File([blob], item.name, {
        type: `application/pdf`,
      });
      let url = window.URL.createObjectURL(file);
      link.href = url;
      link.click();
    }
  }
  
  dataURItoBlob(item: any) {
    const byteString = window.atob(item.fileStream);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {
      type: `application/pdf`,
    });
    return blob;
  }
}
