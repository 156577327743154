import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HistoryService } from '../../../services/history.service';
import { History } from '../../../models/History';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-candidate-payroll-history',
  templateUrl: './candidate-payroll-history.component.html',
  styleUrls: ['./candidate-payroll-history.component.css']
})
export class CandidatePayrollHistoryComponent implements OnInit {

  @Input() candidateID: string = '';
  @Input() isPortalAdmin!:boolean;
  displayLoader: boolean = true;
  loginDisplay = false;
  name: any;
  email: any;
  historyList: any;
  mobileView: boolean = false;
  selected: string = 'history';
  id: string = '';
  public model: any;
  history: History[] = [];
  pageSizeOptions = [10, 20, 50, 100];
  page: any = 1;
  pageSize: any = 10;
  collectionSize = 10;
  loading: boolean = true;

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private route: ActivatedRoute,
    private historyService: HistoryService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(): void {
    if(this.isPortalAdmin){
      this.refreshHistory();
    }
  }
  
  public refreshHistory = (
    page: string = '1',
    query: string = '',
    pageSize: string = '10'
  ) => {
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.history = [];
    this.historyService
      .getEditHistory(
        this.page.toString(),
        this.pageSize,
        `${environment.apiConfig.uri}/admin/Candidates/AstuteEditHistory/${this.candidateID}`
      )
      .subscribe((response: any) => {
        this.history = response.data.editHistories;
        this.collectionSize = response.extraParam.totalCount;
        this.loading = false;
      });
  };
  ngAfterViewInit() {
    if (window.screen.width <= 768) {
      this.mobileView = true;
    }
  }
}
